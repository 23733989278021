define("discourse/plugins/discourse-calendar/discourse/components/modal/post-event-invitees", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse-common/utils/decorators", "discourse-i18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _decorators, _discourseI18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title={{this.title}}
    @closeModal={{@closeModal}}
    class={{concat-class
      (or @model.extraClass "invited")
      "post-event-invitees-modal"
    }}
  >
    <:body>
      <Input
        @value={{this.filter}}
        {{on "input" this.onFilterChanged}}
        class="filter"
        placeholder={{i18n
          "discourse_calendar.discourse_post_event.invitees_modal.filter_placeholder"
        }}
      />
      <ToggleInvitees @viewType={{this.type}} @toggle={{this.toggleType}} />
      <ConditionalLoadingSpinner @condition={{this.isLoading}}>
        {{#if this.invitees}}
          <ul class="invitees">
            {{#each this.invitees as |invitee|}}
              <li class="invitee">
                {{render-invitee invitee}}
                {{#if @model.event.can_act_on_discourse_post_event}}
                  <DButton
                    @icon="trash-alt"
                    @action={{fn this.removeInvitee invitee}}
                  />
                {{/if}}
              </li>
            {{/each}}
          </ul>
        {{else}}
          <p class="no-users">
            {{i18n
              "discourse_calendar.discourse_post_event.models.invitee.no_users"
            }}
          </p>
        {{/if}}
      </ConditionalLoadingSpinner>
    </:body>
  </DModal>
  */
  {
    "id": "jVk6Dk0N",
    "block": "[[[8,[39,0],[[16,0,[28,[37,1],[[28,[37,2],[[30,1,[\"extraClass\"]],\"invited\"],null],\"post-event-invitees-modal\"],null]]],[[\"@title\",\"@closeModal\"],[[30,0,[\"title\"]],[30,2]]],[[\"body\"],[[[[1,\"\\n    \"],[8,[39,3],[[24,0,\"filter\"],[16,\"placeholder\",[28,[37,4],[\"discourse_calendar.discourse_post_event.invitees_modal.filter_placeholder\"],null]],[4,[38,5],[\"input\",[30,0,[\"onFilterChanged\"]]],null]],[[\"@value\"],[[30,0,[\"filter\"]]]],null],[1,\"\\n    \"],[8,[39,6],null,[[\"@viewType\",\"@toggle\"],[[30,0,[\"type\"]],[30,0,[\"toggleType\"]]]],null],[1,\"\\n    \"],[8,[39,7],null,[[\"@condition\"],[[30,0,[\"isLoading\"]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"invitees\"]],[[[1,\"        \"],[10,\"ul\"],[14,0,\"invitees\"],[12],[1,\"\\n\"],[42,[28,[37,10],[[28,[37,10],[[30,0,[\"invitees\"]]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[14,0,\"invitee\"],[12],[1,\"\\n              \"],[1,[28,[35,11],[[30,3]],null]],[1,\"\\n\"],[41,[30,1,[\"event\",\"can_act_on_discourse_post_event\"]],[[[1,\"                \"],[8,[39,12],null,[[\"@icon\",\"@action\"],[\"trash-alt\",[28,[37,13],[[30,0,[\"removeInvitee\"]],[30,3]],null]]],null],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n\"]],[3]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,2],[14,0,\"no-users\"],[12],[1,\"\\n          \"],[1,[28,[35,4],[\"discourse_calendar.discourse_post_event.models.invitee.no_users\"],null]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"]],[]]]]],[1,\"\\n  \"]],[]]]]]],[\"@model\",\"@closeModal\",\"invitee\"],false,[\"d-modal\",\"concat-class\",\"or\",\"input\",\"i18n\",\"on\",\"toggle-invitees\",\"conditional-loading-spinner\",\"if\",\"each\",\"-track-array\",\"render-invitee\",\"d-button\",\"fn\"]]",
    "moduleName": "discourse/plugins/discourse-calendar/discourse/components/modal/post-event-invitees.hbs",
    "isStrictMode": false
  });
  let PostEventInvitees = _exports.default = (_dec = (0, _decorators.debounce)(250), (_class = class PostEventInvitees extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "invitees", _descriptor2, this);
      _initializerDefineProperty(this, "filter", _descriptor3, this);
      _initializerDefineProperty(this, "isLoading", _descriptor4, this);
      _initializerDefineProperty(this, "type", _descriptor5, this);
      this._fetchInvitees();
    }
    get title() {
      return _discourseI18n.default.t(`discourse_calendar.discourse_post_event.invitees_modal.${this.args.model.title || "title_invited"}`);
    }
    toggleType(type) {
      this.type = type;
      this._fetchInvitees(this.filter);
    }
    onFilterChanged() {
      this._fetchInvitees(this.filter);
    }
    async removeInvitee(invitee) {
      await invitee.destroyRecord();
      this._fetchInvitees();
    }
    async _fetchInvitees(filter) {
      try {
        this.isLoading = true;
        const invitees = await this.store.findAll("discourse-post-event-invitee", {
          filter,
          post_id: this.args.model.event.id,
          type: this.type
        });
        this.invitees = invitees;
      } finally {
        this.isLoading = false;
      }
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "invitees", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "filter", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "type", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "going";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleType", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleType"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFilterChanged", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "onFilterChanged"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeInvitee", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeInvitee"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PostEventInvitees);
});